@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';

$openSansFont: 'Open Sans', sans-serif;

.c_subject-link-tile {
    $self: &;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-decoration: none;
    padding-bottom: 2.8em;
    box-sizing: border-box;
    background-color: $color-miljostatus--grey-blue-2;
    color: $color-miljostatus--black;
    width: 100%;
    position: relative;
    transition: all 0.3s ease;
    min-height: 320px;

    @include hover() {
        background-color: $color-miljostatus--grey-blue-3;

        .c_subject-link-tile__subject-name {
            background-color: $color-miljostatus--grey-blue-2;
        }
    }

    &__subject-name {
        display: inline-block;
        font-size: 1.11em;
        margin: 0 auto 1.5em auto;
        background-color: $color-miljostatus--grey-blue-3;
        padding: 0.75em 1.5em 0.55em 1.5em;
        border-bottom: 2px solid $color-miljostatus--dark-blue-1;
        line-height: 1;
        align-self: center;
        transition: all 0.3s ease;

        @media (min-width: 655px) {
            margin-bottom: 1.5em;
        }
    }

    &__icon {
        position: relative;
        margin-bottom: 1.2em;
        width: 3.5em;
        height: 3.5em;
    }

    &__key-number {
        align-self: flex-start;
        width: 100%;
        box-sizing: border-box;
        line-height: 1;
        margin-bottom: 20px;

        font: {
            family: $openSansFont;
            size: 2.87em;
            weight: 700;
        }
        padding: 0 20px;
        word-break: break-all;

        @media (min-width: $large-screen-min) {
            font-size: 3.333em;
        }

        span {
            line-height: 1;
        }
    }

    &__description {
        padding: 0 5% 0.25em 5%;
        line-height: 1.3;
        align-self: flex-start;
        width: 100%;
        box-sizing: border-box;
    }

    &__link-text {
        text-decoration: underline;
        margin: auto;
        padding: 0 20px;
        font-family: $base-font-family;
        position: absolute;
        bottom: 1.25em;
        left: 0;
        right: 0;

        img {
            width: 0.3em;
            height: 0.5em;
            margin-left: 0.5em;
        }
        @media (min-width: $large-screen-min) {
            font-size: .89em;
        }
        @media (min-width: 655px) {
            bottom: 1em;
        }
    }
    // Variants, nested in self
    &#{$self} {
        &--big {
            &#{ $self } {
                padding-bottom: 0;
                flex-direction: column;

                @media (min-width: 1140px) {
                    flex-direction: row;
                    align-items: unset;
                }
            }
            #{ $self } {
                &__left, &__right {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                    text-decoration: none;
                    transition: all 0.3s ease;
                }
                &__left {
		            @media (min-width: $large-screen-min) {
                        position: relative;
                        padding-bottom: 2.8em;
                        width: 40%;
                    }
                }
                &__right {
                    box-sizing: border-box;
                    padding: 20px;
                    height: 100%;
                    width: 100%;
                    max-height: 220px;
                    padding-bottom: 3.8em;


                    @media (min-width: $large-screen-min) {
                        width: 60%;
                        max-height: unset;
                        min-height: 320px;
                        padding-bottom: 20px;
                    }
                }
                &__carousel {
                    background-color: $color-miljostatus--pale-yellow-2;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    &__top {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 1.222em;
                        
                        @media (min-width: $large-screen-min) {
                            font-size: 1.625em;
                        }
                    }
                    &__dot {
                        @include removeButtonAppearance();
                        
                        &--active {
                            &::after {
                                border: 2px solid $color-miljostatus--dark-blue-2;
                            }
                        }
                        &::after {
                            content: '';
                            display: inline-block;
                            height: 1em;
                            width: 1em;
                            border-radius: 50%;
                            background-color: $color-miljostatus--grey-blue-3;
                        }
                    }
                    &__item {
                        width: 100%;
                        opacity: 1;
                        transition: opacity 1s ease;
                        &--hidden {
                            transition: none;
                            opacity: 0;
                            width: 0;
                        }
                    }
                }
            }
        }
        &--2, &--4, &--9 {
            background-color: $color-miljostatus--dark-blue-2;
            color: $color--bright-white;
    
            @include hover() {
                background-color: $color-miljostatus--dark-blue-1;
    
                .c_subject-link-tile__subject-name {
                    background-color: $color-miljostatus--dark-blue-2;
                }
            }
    
            .c_subject-link-tile__subject-name {
                background-color: #36556A;
                border-bottom-color: $color--bright-white;
            }
        }
        &--5, &--7, &--11, &--12 {
            background-color: $color-miljostatus--grey-blue-6;
    
            @include hover() {
                background-color: #9DB0BC;
    
                .c_subject-link-tile__subject-name {
                    background-color: #DFEAF0;
                }
            }
    
            .c_subject-link-tile__subject-name {
                background-color: #D7E3EA;
            }
        }
        &--yellow {
            background-color: $color-miljostatus--pale-yellow-1;
            color: $color-miljostatus--black;
    
            &:hover {
                background-color: darken($color-miljostatus--pale-yellow-1, 5%);
    
                #{ $self } {
                    &__subject-name {
                        background-color: darken($color-miljostatus--pale-yellow-2, 2%);
                    }
                }
            }
    
            #{ $self } {
                &__subject-name {
                    background-color: $color-miljostatus--pale-yellow-2;
                    border-bottom-color: $color-miljostatus--black;
                }
                &__icon {
                    filter: grayscale(1);
                }
            }
        }
    }

    @media print {
        background-color: transparent;
        color: $color--black;
        border: 1px solid $color-miljostatus--grey-blue-4;

        .c_subject-link-tile__subject-name {
            background-color: transparent;
            color: $color--black;
            border: 1px solid $color-miljostatus--grey-blue-4;
            border-top: none;
        }
    }
}