@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';


.c_tile_icon {
	$self: &;
	height: inherit;
    width: inherit;
	display: block;
	margin: 0 auto 1em auto;
	background: transparent;
	max-width: 100%;
	animation: 3s linear .1s;
	

	&--anim-element {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
	}

	&--arter {
		&#{$self}--1 {
			animation: arter1 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
		}
		&#{$self}--2 {
			animation: arter2 2s cubic-bezier(0.4, 0, 1, 1) infinite alternate;
		}
	}
	&--avfall {
		&#{$self}--1 {
			transform-origin: 74% 21%;
			animation: avfall 3s ease infinite;
		}
	}
	&--ferskvann {
		&#{$self}--1 {
			transform-origin: 50% 50%;
			animation: ferskvann1 5s ease infinite;
		}
		&#{$self}--2 {
			animation: ferskvann2 6s ease infinite;
		}
	}
	&--forurensning {
		&#{$self}--1 {
			animation: forurensning 3s ease infinite;
		}
	}
	&--klima {
		&#{$self}--1 {
			animation: klima 3s ease infinite;
		}
	}
	&--naturomrader {
		&#{$self}--1 {
			animation: naturomrader 5s ease infinite;
		}
	}
	&--havogkyst {
		&#{$self}--1 {
			animation: havogkyst 5s ease infinite;
		}
	}
	&--friluftsliv {
		&#{$self}--1 {
			animation: friluftsliv 4s ease infinite;
		}
	}
	&--kulturmiljo {
		&#{$self}--1 {
			animation: kulturmiljo 4s ease infinite;
		}
	}
	&--polaromrader {
		animation-direction: alternate;

		&#{$self}--1 {
			animation: polaromrader 5s ease-in-out infinite alternate;
		}
	}
	&--straling {
		animation-direction: alternate;

		&#{$self}--1 {
			animation: straling1 5s ease-in-out infinite alternate;
		}
	}
	&--miljogifter {
		&#{$self}--1 {
			animation: miljogifter 4s ease infinite;
		}
	}
}

@keyframes arter1 {
	0%	{ transform: rotate(-10deg) translateX(3px);}
	100% { transform: rotate(15deg);}
}
@keyframes arter2 {
	0%	{ transform: rotate(10deg) translateX(-3px);}
	100% { transform: rotate(-15deg);}
}

@keyframes avfall {
	50% {
		transform: rotate(-10deg);
	}
}

@keyframes ferskvann1 {
	50% {
		transform: rotate(-18deg);
	}
}
@keyframes ferskvann2 {
	50% {
		transform: translateY(8px)
	}
}
@keyframes forurensning {
	50% {
		transform: translateX(12px);
		opacity: .5;
	}	
}

@keyframes klima {
	50% {
		transform: translate(4%, -4%);
		opacity: .5;
	}
}
@keyframes naturomrader {
	50% {
		transform: translate(4px, 10px);
		opacity: .5;
	}
}
@keyframes havogkyst {
	50% {
		opacity: .5;
		transform: translate(4px, 3px) rotate(-1deg);
	}
}
@keyframes friluftsliv {
	50% {
		opacity: .2;
	}
}
@keyframes kulturmiljo {
	50% {
		opacity: .2;
	}
}
@keyframes polaromrader {
	0% {
		transform: translate(-10px)
	}
	100% {
		transform: translate(10px);
		opacity: .5;
	}
}
@keyframes straling1 {
	0% {
		transform: scaleY(1.1) translateY(3%)
	}
	50% {
		transform: scaleY(1) translateY(0%)
	}
	100% {
		transform: scaleY(1.1) translateY(-3%)
	}
}

@keyframes miljogifter {
	0% {
		opacity: .5;
	}
	50% {
		transform: translateY(5%);
		opacity: 1;
	}
	100% {
		opacity: .5;
	}
}