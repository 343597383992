@import '../../styles/_variables.scss';
@import '../../styles/_mixins.scss';
@import '../../styles/_colors.scss';
@import '../../styles/_colorsMiljostatus.scss';

$openSansFont: 'Open Sans', sans-serif;

@mixin animationDelayLineByLine($startNumber, $stopNumber, $delay) {
	@for $i from $startNumber through $stopNumber {
		&:nth-child(#{$i}) {
			animation-delay: $delay;
		}
	}
}

@mixin animationDelayOneByOne($startNumber, $stopNumber, $delay) {
	@for $i from $startNumber through $stopNumber {
		&:nth-child(#{$i}) {
			animation-delay: $delay * $i - $delay + 0.1s;
		}
	}
}

.t_start-page {
    &__section-title {
        border-bottom: 1px solid #CCC;
        margin-bottom: 0.8em;
        padding-bottom: 0.35em;
    }

    .c_subject-tile-list {
        list-style-type: none;
        margin: 0.75em 0 0 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        
        @media print {
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }

        li {
            margin: 0 0 1em 0;
            padding: 0;
            display: flex;
            width: 100%;
            /*@include fadeInAnimation();
            @include animationDelayOneByOne(1, 12, 0.5s);

            @media (min-width: 655px) {
                width: 48%;
                margin-bottom: 2%;
                @include animationDelayLineByLine(1, 2, 0.1s);
                @include animationDelayLineByLine(3, 4, 0.6s);
                @include animationDelayLineByLine(5, 6, 1.1s);
                @include animationDelayLineByLine(7, 8, 1.6s);
                @include animationDelayLineByLine(9, 10, 2.1s);
                @include animationDelayLineByLine(11, 12, 2.6s);
            }*/

            @media (min-width: 1140px) {
                height: 343px;
                width: 32%;
                @include animationDelayLineByLine(1, 3, 0.1s);
                @include animationDelayLineByLine(4, 6, 0.6s);
                @include animationDelayLineByLine(7, 9, 1.1s);
                @include animationDelayLineByLine(7, 8, 1.6s);
                @include animationDelayLineByLine(10, 12, 2.1s);
            }
            &.c_subject-tile-list__big-tile-wrapper {
                @media (min-width: 1140px) {
                    width: 100%;
                }
            }
        }

        &--shortcut-list {
            .g_container {
                margin-left: 0;
                margin-right: 0;
                padding: 0;
                width: 100%;
            }

            li {
                .c_shortcut__text-container {
                    margin-right: 0;
                }
            }
        }
    }
}
